<template>
  <div>
    <v-app-bar
      absolute
      :dense="$vuetify.breakpoint.mobile"
      color="blue lighten-5"
      class="ma-0"
    >
      <v-icon>mdi-api</v-icon>
      <v-toolbar-title class="ml-3" v-if="!$vuetify.breakpoint.mobile">
        Empresas SSH
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-text-field
        v-if="mode === 'viewer'"
        hide-details
        dense
        filled
        class="text-field-transparent"
        v-model="search"
        persistent-placeholder
        placeholder="Digite as informações de pesquisa"
        prepend-inner-icon="mdi-magnify"
      ></v-text-field>
      <v-spacer></v-spacer>

      <!-- <v-btn
        v-if="mode === 'viewer'"
        elevation="2"
        :icon="$vuetify.breakpoint.mobile"
        :small="$vuetify.breakpoint.mobile"
        color="secondary"
        class="pa-2 ml-2"
        @click="setMenuMobile"
        v-tooltip.bottom-end="'Menu geral do sistema'"
      >
        {{ msgButton("Menu") }}
        <v-icon v-if="!$store.state.isMenuVisibleMobile">mdi-menu-down </v-icon>
        <v-icon v-else> mdi-menu </v-icon>
      </v-btn> -->
      <v-btn
        v-if="mode === 'viewer'"
        elevation="2"
        :small="$vuetify.breakpoint.mobile"
        color="primary"
        :icon="$vuetify.breakpoint.mobile"
        @click="loadAccessCNPJ(accessCNPJ, 'insert')"
        class="pa-2"
        v-tooltip.bottom-end="'Novo registro no cadastro de API'"
      >
        Novo <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-btn
        v-if="mode === 'viewer'"
        elevation="2"
        :icon="$vuetify.breakpoint.mobile"
        :small="$vuetify.breakpoint.mobile"
        color="secondary"
        class="pa-2 ml-2"
        @click="setMenuMobile"
        v-tooltip.bottom-end="'Menu geral do sistema'"
      >
        {{ msgButton("Menu") }}
        <v-icon v-if="!$store.state.isMenuVisibleMobile">mdi-menu-down </v-icon>
        <v-icon v-else> mdi-menu </v-icon>
      </v-btn>

      <v-btn
        v-if="mode === 'save' || mode === 'insert'"
        elevation="2"
        :small="$vuetify.breakpoint.mobile"
        color="primary"
        :icon="$vuetify.breakpoint.mobile"
        @click="save"
        class="pa-2"
        :disabled="!valid"
      >
        {{ msgButton("Salvar") }} <v-icon>mdi-check</v-icon>
      </v-btn>
      <v-btn
        v-if="mode === 'remove'"
        elevation="2"
        :small="$vuetify.breakpoint.mobile"
        color="error"
        :icon="$vuetify.breakpoint.mobile"
        @click="remove"
        class="pa-2"
      >
        {{ msgButton("Excluir") }} <v-icon>mdi-delete</v-icon>
      </v-btn>

      <v-btn
        v-if="mode === 'save' || mode === 'insert' || mode === 'remove'"
        elevation="2"
        :small="$vuetify.breakpoint.mobile"
        :icon="$vuetify.breakpoint.mobile"
        @click="reset"
        class="ml-2"
      >
        {{ msgButton("Cancelar") }} <v-icon>mdi-cancel</v-icon>
      </v-btn>
    </v-app-bar>

    <v-sheet class="overflow-y-auto mt-6" max-height="1200">
      <v-container
        id="containerEdicao"
        fluid
        class="admin-pages"
        :style="heightScroll"
      >
        <div class="api-admin">
          <v-form
            ref="form"
            v-if="mode !== 'viewer'"
            class="mt-4 mt-lg-8"
            v-model="valid"
            lazy-validation
          >
            <v-row v-if="accessCNPJ.id">
              <v-col cols="12" md="12" sm="12">
                <v-text-field
                  v-model="accessCNPJ.id"
                  label="ID"
                  outlined
                  readonly
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6" sm="12">
                <v-text-field
                  v-model="accessCNPJ.cnpj"
                  label="CNPJ"
                  required
                  outlined
                  hide-details="auto"
                  :rules="rulesCNPJ"
                  :readonly="accessCNPJ.id > 0"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-text-field
                  v-model="accessCNPJ.nome_empresa"
                  label="Nome da Empresa"
                  outlined
                  hide-details="auto"
                  :readonly="mode === 'remove'"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>

          <div v-if="mode === 'viewer'">
            <hr class="ma-5" />

            <v-data-table
              dense
              :headers="headers"
              :items="accessCNPJs"
              :search="search"
              item-key="cnpj"
              :items-per-page="15"
              class="elevation-1 pt-3"
              :footer-props="{
                'items-per-page-text': 'CNPJs por página',
                pageText: '{0}-{1} de {2}',
              }"
            >
              <template v-slot:item.action="{ item }">
                <v-btn
                  elevation="2"
                  icon
                  @click="loadAccessCNPJ(item)"
                  class="mr-2"
                  v-tooltip.bottom-end="'Editar registro da tabela'"
                  ><v-icon>mdi-pencil</v-icon></v-btn
                >
                <v-btn
                  elevation="2"
                  icon
                  @click="loadAccessCNPJ(item, 'remove')"
                  class=""
                  v-tooltip.bottom-end="'Remover registro da tabela'"
                  ><v-icon>mdi-delete</v-icon></v-btn
                >
              </template>
            </v-data-table>
          </div>
        </div>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
import { baseApiUrl, showSucess, showError, validarCNPJ } from "@/global";
import axios from "axios";

export default {
  name: "AdminAcess",

  data: function() {
    return {
      valid: false,
      rulesCNPJ: [
        (value) => !!value || "Campo obrigatório",
        (value) => validarCNPJ(value) || "CNPJ inválido",
        (value) => (value && value.length == 14) || "Tamanho de 14 caracteres",
        (value) =>
          !isNaN(parseFloat(value)) || "Digito inválido. Somente números",
      ],
      mode: "viewer",
      search: "",
      accessCNPJ: {},
      accessCNPJs: [],
      headers: [
        { text: "ID", align: "start", sortable: true, value: "id" },
        { text: "CNPJ", align: "start", sortable: true, value: "cnpj" },
        {
          text: "Nome da Empresa",
          align: "start",
          sortable: true,
          value: "nome_empresa",
        },
        { text: "Ações", align: "start", value: "action", sortable: false },
      ],
    };
  },

  methods: {
    loadAccessCNPJs() {
      axios
        .get(`${baseApiUrl}/cnpj_ssh`)
        .then((res) => {
          this.accessCNPJs = res.data;
        })
        .catch((err) => console.error(err));
    },
    setMenuMobile() {
      if (this.$store.state.isMenuVisibleMobile) {
        this.$store.state.isMenuVisibleMobile = false;
      } else {
        this.$store.state.isMenuVisibleMobile = true;
      }
    },

    save() {
      if (this.$refs.form.validate()) {
        const method = this.accessCNPJ.id ? "put" : "post";
        const id = this.accessCNPJ.id ? `/${this.accessCNPJ.id}` : "";

        axios[method](`${baseApiUrl}/cnpj_ssh${id}`, this.accessCNPJ)
          .then(() => {
            showSucess();
            this.reset();
          })
          .catch((error) => {
            showError(error);
          });
      }
    },

    remove() {
      if (this.accessCNPJ.id) {
        axios
          .delete(`${baseApiUrl}/cnpj_ssh/${this.accessCNPJ.id}`)
          .then(() => {
            showSucess("Registro removido com sucesso");
            this.reset();
          })
          .catch(showError);
      }
    },
    msgButton: function(value) {
      if (this.$vuetify.breakpoint.mobile) {
        return "";
      } else {
        return value;
      }
    },

    loadAccessCNPJ(accessCNPJ, mode = "save") {
      this.mode = mode;
      this.accessCNPJ = { ...accessCNPJ };
      this.toTop();
    },

    reset() {
      this.$refs.form.reset();
      this.accessCNPJ = {};
      this.loadAccessCNPJs();
      this.mode = "viewer";
    },
  },

  mounted() {
    this.loadAccessCNPJs();
  },
};
</script>

<style>
.text-field-transparent .v-input__slot {
  background: transparent !important;
}
</style>
